import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useMemo, useRef, useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Label,
  Button,
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
} from "reactstrap"
import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import CustomSelect from "components/Common/SelectBoxes/CustomSelect"
import { isEmpty, isNumber, isObject } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { getProviderFacilities } from "store/provider-dashboard/provider-dashboard-action"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import {
  checkProviderOutInvoiceNumber,
  getProviderOutProjects,
  getProviderOutReceipts,
} from "store/provider-out/provider-out-action"
import ReceiptsList from "./ReceiptsList"
import SubmitedList from "./SubmitedList"
import { getSelectValueWithDefault } from "utils/providerOutUtils"
import { removeDocument } from "utils/utils"
import { providerOutAction } from "store/provider-out/provider-out-slice"
import {
  DEFAULT_DISTRIBUTED_DETAILS,
  DEFAULT_DISTRIBUTION_REASON,
  DISTRIBUTEDDETAIL,
  DISTRIBUTEDTYPE,
  DISTRIBUTIONREASON,
} from "constants/data"
import { getProviderInAvailableItems } from "store/provider-in/provider-in-action"

const ProviderOutForm = ({
  onSubmit,
  loading,
  formMode,
  error,
  warehosues,
  providers,
  categories,
  items,
  distribution,
  ips,
  provinces,
  facilities,
  receipts,
  projects,
  providerAccounts,
}) => {
  const [selectedFiles, setselectedFiles] = useState({})
  const [submited, setSubmited] = useState([])
  const submissionDateRef = useRef(null)
  const [customErrors, setCustomErrors] = useState({})
  const [distributedType, setDistributedType] = useState({
    value: "IP",
    label: "IP",
  })
  const [facilityVisible, setFacilityVisible] = useState(true)

  const [invoiceNumber, setInvoiceNumber] = useState("")

  const storeQuantityError = useSelector(
    state => state.providerOut.storeQuantityError
  )

  const invoiceStatus = useSelector(state => state.providerOut.invoiceCheck)

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files[0])
  }

  const onSubmitAddHandler = value => {
    if (invoiceStatus?.msg != null) {
      setCustomErrors(prev => {
        return {
          ...prev,
          invoiceNumber:
            "Invalid Invoice Number. Please enter a valid current invoice number or leave the field blank.",
        }
      })
      return
    } else {
      setCustomErrors(prev => {
        return { ...prev, invoiceNumber: null }
      })
    }

    if (isEmpty(distributeValidation.values.province_id)) {
      setCustomErrors(prev => {
        return { ...prev, province: "Please Select your provicne" }
      })
      return
    } else {
      setCustomErrors(prev => {
        return { ...prev, province: null }
      })
    }

    if (isEmpty(distributeValidation.values.date)) {
      setCustomErrors(prev => {
        return { ...prev, date: "Submission date is required" }
      })
      return
    } else {
      setCustomErrors(prev => {
        return { ...prev, date: null }
      })
    }

    if (distributeValidation.values.warehouse_disribute === "HFs") {
      if (isEmpty(distributeValidation.values.facility_id)) {
        setCustomErrors(prev => {
          return { ...prev, facility: "facility date is required" }
        })
        return
      } else {
        setCustomErrors(prev => {
          return { ...prev, facility: null }
        })
      }
    }

    if (distributeValidation.values.distributed_type === "Provider") {
      console.log(!isNumber(distributeValidation.values.provided_for))
      if (!isNumber(distributeValidation.values.provided_for)) {
        setCustomErrors(prev => {
          return { ...prev, providedFor: "provider for is required" }
        })
        return
      } else {
        setCustomErrors(prev => {
          return { ...prev, providedFor: null }
        })
      }
    }

    setSubmited(prev => {
      return [
        ...prev,
        {
          warehouse_id: getSelectValueWithDefault(
            distributeValidation.values.warehouse_id,
            warehosues
          ),
          provider_id: getSelectValueWithDefault(
            distributeValidation.values.provider_id,
            providers
          ),
          province_id: distributeValidation.values.province_id.value,

          facility_id: isObject(distributeValidation.values.facility_id)
            ? distributeValidation.values.facility_id.value
            : null,

          item_category_id: distributeValidation.values.item_category_id.value,
          item_id: distributeValidation.values.item_id.value,

          ip_id:
            distributeValidation.values.provided_for !== "Provider"
              ? getSelectValueWithDefault(
                  distributeValidation.values.ip_id,
                  ips
                )
              : "",
          distributor: distributeValidation.values.distributor,
          provider_in_id: value.provider_in_id,
          out_quantity: value.out_quantity,
          unfpa_out_quantity: value.unfpa_quantity,
          unfpa_total_value: value.total_value,
          date: distributeValidation.values.date,
          warehouse_disribute:
            distributedType?.value === "Provider"
              ? DISTRIBUTEDDETAIL[0].value
              : DISTRIBUTEDDETAIL[1].value,
          other_hfs: distributeValidation.values.other_hfs,
          distributed_type: distributeValidation.values.distributed_type,
          provided_for: distributeValidation.values.provided_for,
          batch_number: value.batch_number,
          project_id: value.project_id,
          distribution_reason: isObject(
            distributeValidation.values.distribution_reason
          )
            ? distributeValidation.values.distribution_reason.value
            : distributeValidation.values.distribution_reason,
          item1: distributeValidation.values.province_id.label,
          item2: isObject(distributeValidation.values.facility_id)
            ? distributeValidation.values.facility_id.label
            : null,
          item3: distributeValidation.values.item_id.label,
          item4: value.batch_number,
          item5: value.out_quantity,
          invoice_number: invoiceNumber,
        },
      ]
    })
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const dispatch = useDispatch()
  const distributeValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      warehouse_id: distribution ? distribution?.warehouse_id : "",
      provider_id: distribution ? distribution?.provider_id : "",
      item_category_id: distribution ? distribution?.item_category_id : "",
      item_id: distribution ? distribution?.item_id : "",
      ip_id: distribution ? distribution?.ip_id : "",
      province_id: distribution ? distribution?.province_id : "",
      facility_id: distribution ? distribution?.facility_id : "",
      provider_in_id: distribution ? distribution?.provider_in_id : "",
      distributor: distribution ? distribution?.distributor : "",
      warehouse_disribute: distribution
        ? distribution?.warehouse_disribute
        : "Warehouse",
      distributed_type: distribution ? distribution?.distributed_type : "IP",
      other_hfs: distribution ? distribution?.other_hfs : "",
      provided_for: distribution ? distribution?.provided_for : "",
      project_id: distribution ? distribution?.project_id : "",
      distribution_reason: distribution
        ? distribution?.distribution_reason
        : "",
    },
    validationSchema: Yup.object().shape({
      date: Yup.string().required("This in quantity IP is required"),
    }),

    onSubmit: values => {
      onSubmit(submited, selectedFiles)
    },
  })

  const resetAllForm = () => {
    distributeValidation.resetForm()
    submissionDateRef.current.flatpickr.clear()
  }

  const onWarehouseChangeHander = value => {
    distributeValidation.setFieldValue("warehouse_id", value)
  }

  const onProviderChangeHandler = value => {
    distributeValidation.setFieldValue("provider_id", value)
  }

  const onDistributionReasonChangeHandler = value => {
    const type = distributeValidation.values.distributed_type
    if (type == "Provider") {
      distributeValidation.setFieldValue("distribution_reason", value)
    }
  }

  const onIpChangeHandler = value => {
    distributeValidation.setFieldValue("ip_id", value)
  }

  const onProvinceChangeHandler = value => {
    distributeValidation.setFieldValue("province_id", value)
    distributeValidation.setFieldValue("facility_id", "")
    dispatch(getProviderFacilities(value.value))
  }

  const onItemCategoryChangeHandler = value => {
    if (isEmpty(distributeValidation.values.date)) {
      setCustomErrors(prev => {
        return { ...prev, date: "Submission date is required" }
      })
      return
    } else {
      setCustomErrors(prev => {
        return { ...prev, date: null }
      })
    }
    distributeValidation.setFieldValue("item_category_id", value)
    dispatch(getProviderInAvailableItems({ item_category_id: value.value }))
    distributeValidation.setFieldValue("item_id", null)
  }

  const onItemChangeHandler = value => {
    distributeValidation.setFieldValue("item_id", value)
    distributeValidation.setFieldValue("project_id", "")
    dispatch(getProviderOutProjects({ itemId: value.value }))
  }

  const onProjectChangeHandler = value => {
    distributeValidation.setFieldValue("project_id", value)
    const date = distributeValidation.values.date
    const itemId = distributeValidation.values.item_id
    dispatch(
      getProviderOutReceipts({
        itemId: itemId,
        submitionDate: date,
        project_id: value.value,
      })
    )
  }

  const onDistributeDetailChangeHandler = value => {
    distributeValidation.setFieldValue("warehouse_disribute", value)
    distributeValidation.setFieldValue("facility_id", "")
  }
  const onDistributedTypeChangeHandler = value => {
    setDistributedType(value)
    if (value.value === "Provider") {
      setFacilityVisible(false)
      distributeValidation.setFieldValue(
        "warehouse_disribute",
        DEFAULT_DISTRIBUTED_DETAILS
      )
      distributeValidation.setFieldValue("facility_id", "")
      distributeValidation.setFieldValue("distributor", "")
      distributeValidation.setFieldValue("ip_id", "")
    } else {
      setFacilityVisible(true)
      distributeValidation.setFieldValue("provided_for", "")
      distributeValidation.setFieldValue("other_hfs", "")
    }
    distributeValidation.setFieldValue(
      "distribution_reason",
      DEFAULT_DISTRIBUTION_REASON
    )
    distributeValidation.setFieldValue("distributed_type", value.value)
  }
  const onFacilityChangeHandler = value => {
    distributeValidation.setFieldValue("facility_id", value)
  }
  const onProvidedForChangehandler = value => {
    distributeValidation.setFieldValue("provided_for", value.value)
  }

  const filteredItems = useMemo(
    () =>
      items.map(item => ({
        value: item.id,
        label: `${item.unfp_item_name} ( ${item.item_name} )`,
      })),
    [items]
  )

  const onInvoiceNumberChange = e => {
    setInvoiceNumber(e.target.value)
    if (e.target.value != "") {
      dispatch(checkProviderOutInvoiceNumber(e.target.value))
    } else {
      dispatch(providerOutAction.resetInvoiceCheck())
    }
  }

  useEffect(() => {
    return () => {
      dispatch(providerOutAction.resetInvoiceCheck())
    }
  }, [])

  const onSubmitionDateChange = value => {
    dispatch(providerOutAction.resetReceiptOut())
    distributeValidation.setFieldValue(
      "date",
      moment(value[0]).format("YYYY-MM-DD")
    )
  }

  return (
    <Card>
      <CardBody>
        <CustomHeaderTitle
          loading={loading}
          title={formMode === "Edit" ? "Edit Distribution" : "Add Distribution"}
          lgColumn={4}
          rowCount={false}
          orderingList={false}
          searchBox={false}
          rightBtn={true}
          rightBtnTitle={"Back"}
          rightBtnRoute={"/provider-distributions"}
        />

        <Form
          onSubmit={e => {
            e.preventDefault()
            distributeValidation.handleSubmit()
            return false
          }}
        >
          <Dropzone
            onDrop={acceptedFiles => {
              handleAcceptedFiles(acceptedFiles)
            }}
            useFsAccessApi={false}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div className="dz-message needsclick mt-2" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>Drop files here or click to upload.</h4>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="dropzone-previews mt-3" id="file-previews">
            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete file">
              <div className="p-2">
                <Row className="align-items-center">
                  <Col>
                    <Link to="#" className="text-muted font-weight-bold">
                      {selectedFiles.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{selectedFiles.formattedSize}</strong>
                    </p>
                    {!isEmpty(selectedFiles) && (
                      <Button
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                          removeDocument(setselectedFiles)
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            </Card>
            {error?.document && (
              <p className="text-danger p-2">{error?.document}</p>
            )}
          </div>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">DIS Number</Label>
                <Input
                  name="invoice_number"
                  placeholder="DIS Number"
                  type="text"
                  disabled={invoiceStatus?.status || submited?.length > 0}
                  onChange={onInvoiceNumberChange}
                  onBlur={onInvoiceNumberChange}
                  value={invoiceNumber}
                  invalid={invoiceStatus?.msg != null}
                  valid={invoiceStatus?.status}
                />
                {invoiceStatus?.msg != null && (
                  <FormFeedback type="invalid">
                    {invoiceStatus?.msg}
                  </FormFeedback>
                )}

                <p style={{ color: "gray", paddingTop: 20 }}>
                  <strong>Note: </strong>
                  This box is for entering a previous invoice number if you want
                  to add a new distribution to an existing invoice. If you're
                  generating a new invoice number, please leave this field
                  blank.
                </p>
              </div>
            </Col>
          </Row>

          <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">Account Type</Label>
                <CustomSelect
                  name="distributed_type"
                  options={DISTRIBUTEDTYPE}
                  value={distributedType}
                  onChange={onDistributedTypeChangeHandler}
                />
              </Col>

              <Col>
                <Label className="form-label">Transaction Type</Label>
                <CustomSelect
                  name="distribution_reason"
                  options={DISTRIBUTIONREASON}
                  isDisabled={distributedType?.value == "IP"}
                  value={
                    isObject(distributeValidation.values.distribution_reason)
                      ? distributeValidation.values.distribution_reason
                      : DISTRIBUTIONREASON.find(
                          reason =>
                            distributeValidation.values.distribution_reason ===
                            reason.value
                        ) || DISTRIBUTIONREASON[0]
                  }
                  onChange={onDistributionReasonChangeHandler}
                />
              </Col>
            </Row>
          </div>

          <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">Warehouses</Label>
                {warehosues && (
                  <CustomSelect
                    name="warehouse_id"
                    options={warehosues}
                    value={
                      isObject(distributeValidation.values.warehouse_id)
                        ? distributeValidation.values.warehouse_id
                        : warehosues.find(
                            ware =>
                              distributeValidation.values.warehouse_id ===
                              ware.value
                          ) || warehosues[0]
                    }
                    onChange={onWarehouseChangeHander}
                  />
                )}
              </Col>
              <Col>
                <Label className="form-label">Providers</Label>
                {providers && (
                  <CustomSelect
                    name="warehouse_id"
                    options={providers}
                    value={
                      isObject(distributeValidation.values.provider_id)
                        ? distributeValidation.values.provider_id
                        : providers.find(
                            prov =>
                              distributeValidation.values.provider_id ===
                              prov.value
                          ) || providers[0]
                    }
                    onChange={onProviderChangeHandler}
                  />
                )}
              </Col>
            </Row>
          </div>

          <div className="mb-3">
            <Row>
              {distributedType?.value === "IP" ? (
                <Col>
                  <Label className="form-label">IPs</Label>
                  {ips && (
                    <CustomSelect
                      name="ip_id"
                      options={ips}
                      value={
                        isObject(distributeValidation.values.ip_id)
                          ? distributeValidation.values.ip_id
                          : ips.find(
                              p => distributeValidation.values.ip_id === p.value
                            ) || ips[0]
                      }
                      onChange={onIpChangeHandler}
                    />
                  )}
                </Col>
              ) : (
                <Col>
                  <Label className="form-label">Provider Accounts</Label>
                  {providerAccounts && (
                    <CustomSelect
                      name="provided_for"
                      options={providerAccounts}
                      value={
                        isObject(distributeValidation.values.provided_for)
                          ? distributeValidation.values.provided_for
                          : providerAccounts.find(
                              p =>
                                distributeValidation.values.provided_for ===
                                p.value
                            ) || { value: "", label: "" }
                      }
                      onChange={onProvidedForChangehandler}
                    />
                  )}
                </Col>
              )}

              <Col>
                <Label className="form-label">Distributor Name</Label>
                <Input
                  name="distributor"
                  placeholder=" Distributor Name"
                  type="text"
                  disabled={distributedType?.value === "Provider"}
                  onChange={distributeValidation.handleChange}
                  onBlur={distributeValidation.handleBlur}
                  value={distributeValidation.values.distributor || ""}
                  invalid={
                    (distributeValidation.touched.distributor &&
                      distributeValidation.errors.distributor) ||
                    error?.distributor?.[0]
                      ? true
                      : false
                  }
                />
                {(distributeValidation.touched.distributor &&
                  distributeValidation.errors.distributor) ||
                error?.distributor?.[0] ? (
                  <FormFeedback type="invalid">
                    {distributeValidation.errors.distributor ||
                      error?.distributor?.[0]}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
          </div>

          <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">Distribute Details</Label>
                <CustomSelect
                  name="warehouse_disribute"
                  isDisabled={true}
                  options={DISTRIBUTEDDETAIL}
                  value={
                    distributedType?.value === "Provider"
                      ? DISTRIBUTEDDETAIL[0]
                      : DISTRIBUTEDDETAIL[1]
                  }
                  // value={
                  //   distributedType?.value === "Provider"
                  //     ? DISTRIBUTEDDETAIL[0]
                  //     : DISTRIBUTEDDETAIL[1]
                  // }
                  onChange={onDistributeDetailChangeHandler}
                />
              </Col>
              <Col>
                <Label className="form-label">Provinces</Label>
                {provinces && (
                  <CustomSelect
                    name="province_id"
                    options={provinces}
                    value={
                      isObject(distributeValidation.values.province_id)
                        ? distributeValidation.values.province_id
                        : provinces.find(
                            prov =>
                              distributeValidation.values.province_id ===
                              prov.value
                          )
                    }
                    onChange={onProvinceChangeHandler}
                  />
                )}
              </Col>
            </Row>
          </div>

          <div className="mb-3">
            <Label className="form-label">Submission Date</Label>
            <Flatpickr
              ref={submissionDateRef}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                altInput: true,
                altFormat: "Y-m-d",
                dateFormat: "Y-m-d",
                defaultDate: distributeValidation.values.date,
                maxDate: moment().format("YYYY-MM-DD"),
              }}
              onChange={onSubmitionDateChange}
            />
            {(distributeValidation.touched.date &&
              distributeValidation.errors.date) ||
            error?.date?.[0] ? (
              <FormFeedback type="invalid">
                {distributeValidation.errors.date || error?.date?.[0]}
              </FormFeedback>
            ) : null}
          </div>

          {facilityVisible && (
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="form-label">Facilities</Label>
                  {facilities && (
                    <CustomSelect
                      name="facility_id"
                      options={facilities}
                      value={
                        isObject(distributeValidation.values.facility_id)
                          ? distributeValidation.values.facility_id
                          : facilities.find(
                              facil =>
                                distributeValidation.values.facility_id ===
                                facil.value
                            )
                      }
                      onChange={onFacilityChangeHandler}
                    />
                  )}
                </div>
              </Col>
              {distributeValidation.values.facility_id?.label ===
                "Others( Others )" && (
                <Col>
                  <Label className="form-label">Other HFs</Label>
                  <Input
                    name="other_hfs"
                    placeholder=" Other HFs"
                    type="text"
                    onChange={distributeValidation.handleChange}
                    onBlur={distributeValidation.handleBlur}
                    value={distributeValidation.values.other_hfs || ""}
                    invalid={
                      (distributeValidation.touched.other_hfs &&
                        distributeValidation.errors.other_hfs) ||
                      error?.other_hfs?.[0]
                        ? true
                        : false
                    }
                  />
                  {(distributeValidation.touched.other_hfs &&
                    distributeValidation.errors.other_hfs) ||
                  error?.other_hfs?.[0] ? (
                    <FormFeedback type="invalid">
                      {distributeValidation.errors.other_hfs ||
                        error?.other_hfs?.[0]}
                    </FormFeedback>
                  ) : null}
                </Col>
              )}
            </Row>
          )}

          <div className="mb-3">
            <Row>
              <Col>
                <Label className="form-label">Item Categories</Label>
                {categories && (
                  <CustomSelect
                    name="item_category_id"
                    options={categories}
                    value={
                      isObject(distributeValidation.values.item_category_id)
                        ? distributeValidation.values.item_category_id
                        : categories.filter(
                            cate =>
                              distributeValidation.values.item_category_id ===
                              cate.value
                          )
                    }
                    onChange={onItemCategoryChangeHandler}
                  />
                )}
              </Col>
              <Col>
                <Label className="form-label">Items</Label>
                {filteredItems && (
                  <CustomSelect
                    name="item_id"
                    options={filteredItems}
                    value={
                      isObject(distributeValidation.values.item_id)
                        ? distributeValidation.values.item_id
                        : filteredItems.find(
                            item =>
                              distributeValidation.values.item_id === item.value
                          )
                    }
                    onChange={onItemChangeHandler}
                  />
                )}
              </Col>
            </Row>
          </div>

          {projects && (
            <div className="mb-3">
              <Row>
                <Col>
                  <Label className="form-label">Projects</Label>
                  <CustomSelect
                    name="project_id"
                    options={projects}
                    value={
                      isObject(distributeValidation.values.project_id)
                        ? distributeValidation.values.project_id
                        : projects.filter(
                            project =>
                              distributeValidation.values.project_id ===
                              project.value
                          )
                    }
                    onChange={onProjectChangeHandler}
                  />
                </Col>
              </Row>
            </div>
          )}

          {customErrors &&
            Object.values(customErrors).map((err, key) => (
              <p className="text-danger" key={key}>
                {err}
              </p>
            ))}

          {receipts && (
            <ReceiptsList
              data={receipts}
              onSubmitAddHandler={onSubmitAddHandler}
              submited={submited}
            />
          )}

          {storeQuantityError?.length > 0 &&
            storeQuantityError?.map((item, key) => (
              <p className="text-danger" key={key}>
                {item}
              </p>
            ))}
          {submited.length > 0 && (
            <SubmitedList submited={submited} setSubmited={setSubmited} />
          )}

          <div className="d-flex flex-wrap gap-2">
            <Button type="submit" color="primary" disabled={loading}>
              {loading
                ? "Please Wait..."
                : formMode === "Add"
                ? "Save All"
                : "Update"}
            </Button>
          </div>
        </Form>
        <br />
      </CardBody>
    </Card>
  )
}

export default ProviderOutForm
