import CustomHeaderTitle from "components/Common/CustomHeaderTitle"
import GeneralTables from "components/Common/GeneralTable"
import React from "react"
import { Card, CardBody } from "reactstrap"

const ProviderChartOutItemsList = ({ data, loading }) => {
  const total = data?.reduce(
    (curr, obj) => curr + parseInt(obj.out_quantity),
    0
  )

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CustomHeaderTitle
            loading={loading}
            title={"Provider Distributions"}
            lgColumn={4}
            rowCount={false}
            orderingList={false}
            searchBox={false}
            rightBtn={true}
            rightBtnTitle={"Back"}
            rightBtnRoute={"/dashboard"}
          />

          <GeneralTables
            data={data}
            loading={loading}
            headers={[
              "#",
              "PROJECT",
              "ITEM ID",
              "IP-ITEM DESCRIPTION",
              "BATCH NUMBER",
              "EXPIRE DATE",
              "OUT QUANTITY",
              "DISTRIBUTION DATE",
            ]}
            renderItem={(outs, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{outs.project?.name}</td>
                <td>{outs.item?.unfp_item_name}</td>
                <td>{outs.item?.ip_item_description}</td>
                <td>{outs?.provider_in?.batch_number}</td>
                <td>{outs?.provider_in?.expire_date}</td>
                <td>{outs.out_quantity}</td>
                <td>{outs.date}</td>
              </tr>
            )}
          />
          <br />

          <div style={{ textAlign: "center", fontSize: 18, fontWeight: 500 }}>
            <p>Total: {total}</p>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ProviderChartOutItemsList
